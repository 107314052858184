import { Module } from 'vuex';
import to from 'await-to-js';
import Papa from 'papaparse';
import BigNumber from 'bignumber.js';
import axios from 'axios';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyStorage, bloqifyFunctions, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { Payment, PaymentStatus } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { generateFileMd5Hask } from '../utils/files';
import BatchInstance from './batches';

const SET_ASSET = 'SET_ASSET';

export const assetChecks = (asset: Asset): boolean => {
  const requiredFields = [
    'name', 'city', 'country', 'startDateTime', 'endDateTime', 'investmentCase', 'propertyDetails', 'risks',
    'euroMin', 'totalValueEuro', 'sharePrice', 'emissionCost', 'dividendsFormat',
  ];

  // Allow zeroes
  if (requiredFields.some((field): boolean => !asset[field] && asset[field] !== 0)) {
    return false;
  }

  if (asset.dividendsFormat.length === 0) {
    return false;
  }

  return true;
};

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_ASSET](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async getAssetById(
      { commit },
      { id }: { id: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'getAssetById' });

      const [getAssetError, getAssetData] = await to(bloqifyFirestore.collection('assets').doc(id).get());
      const error = getAssetError || (!getAssetData?.exists && Error('Not Found'));
      if (error) {
        commit(SET_ASSET, { status: DataContainerStatus.Error, payload: error, operation: 'getAssetById' });
        return;
      }

      commit(SET_ASSET, { status: DataContainerStatus.Success, payload: getAssetData!.data(), operation: 'getAssetById' });
    },
    async createAsset(
      { commit },
      { asset }: { asset: Asset },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'createAsset' });

      const dateNow = firebase.firestore.Timestamp.now();
      const storageRef = bloqifyStorage.ref();
      const assetRef = bloqifyFirestore.collection('assets').doc();
      const assetClone = { ...asset };
      const files: { [key: string]: File[] } = {};
      const storageChildren: { file: File, ref: firebase.storage.Reference }[] = [];
      const filesKeyNames = ['images', 'floorPlanImages', 'prospectus', 'investmentFiles', 'brochure', 'assetTerms', 'assetSubscriptionDoc'];

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNames.forEach((keyName): void => {
        assetClone[keyName] = assetClone[keyName].map((file: File): string => {
          const fullPath = `assets/${assetRef.id}/${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // Uploading all files including hashes
      try {
        await Promise.all(storageChildren.map(async (child): Promise<any> => {
          const md5Hash = await generateFileMd5Hask(child.file, true);

          return child.ref.put(child.file, { customMetadata: { md5Hash } });
        }));
      } catch (e) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'createAsset' });
      }

      // Data fixing
      if (assetClone.startDateTime) {
        // @ts-ignore
        assetClone.startDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.startDateTime);
      }
      if (assetClone.endDateTime) {
        // @ts-ignore
        assetClone.endDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.endDateTime);
      }

      // @ts-ignore
      assetClone.createdDateTime = firebase.firestore.FieldValue.serverTimestamp();

      // @ts-ignore
      assetClone.updatedDateTime = firebase.firestore.FieldValue.serverTimestamp();

      const updateTotalValueShares = (): number => {
        if (!isNaN(assetClone.totalValueEuro) && !isNaN(assetClone.sharePrice)) {
          // It can be 0/0 = NaN
          return (assetClone.totalValueEuro / assetClone.sharePrice) || 0;
        }

        return 0;
      };

      assetClone.totalValueShares = updateTotalValueShares();
      assetClone.sharesAvailable = assetClone.totalValueShares;
      assetClone.totalValueEuro = assetClone.totalValueEuro || 0;
      assetClone.euroMin = assetClone.euroMin || 0;
      assetClone.sharePrice = assetClone.sharePrice || 0;
      assetClone.deleted = false;

      const [createError] = await to(assetRef.set(assetClone));
      if (createError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: createError, operation: 'createAsset' });
      }

      // only Equity will have valuations
      if (assetClone.fundType === 'equity' || assetClone.fundType === 'CV') {
        const valuationRef = assetRef.collection('valuations').doc();
        const [createValuationError] = await to(valuationRef.set({
          asset: assetRef,
          amount: assetClone.totalValueEuro,
          description: 'Initial valuation',
          date: dateNow,
          createdDateTime: dateNow,
          deleted: false,
        }));
        if (createValuationError) {
          return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: createValuationError, operation: 'createAsset' });
        }
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, payload: { id: assetRef.id }, operation: 'createAsset' });
    },
    async updateAsset(
      { commit },
      { asset }: { asset: Asset },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'updateAsset' });

      const storageRef = bloqifyStorage.ref();
      const { id: assetId, ...assetClone } = asset;
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const files: { [key: string]: File[] } = {};
      const storageChildren: { file: File, ref: firebase.storage.Reference }[] = [];
      const filesKeyNames = ['images', 'floorPlanImages', 'prospectus', 'investmentFiles', 'brochure', 'assetTerms', 'assetSubscriptionDoc'];

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNames.forEach((keyName): void => {
        assetClone[keyName] = assetClone[keyName].map((file: File): string => {
          const fullPath = `assets/${assetRef.id}/${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // The comparison of the md5Hash could have been done here via JavaScript (customMetadata.md5Hash) but it's also possible via
      // Firestore rules. The only caveat is that the error handling is not good at all, we cannot identify
      // what kind of error we are getting from the rules, only no permission.
      let storageResultsAndErrors: firebase.storage.UploadTaskSnapshot | firebase.functions.HttpsError[];
      try {
        storageResultsAndErrors = await Promise.all(storageChildren.map(async (child): Promise<any> => {
          const md5Hash = await generateFileMd5Hask(child.file, true);

          // Return all errors if there are any
          return child.ref.put(child.file, { customMetadata: { md5Hash } })
            .catch((err): Error => err);
        }));
      } catch (e) {
        // Set error if there is any other kind of error than a FirebaseStorageError
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'updateAsset' });
      }

      // Check if there is any other FirebaseStorageError error than 'storage/unauthorized',
      // since it's the only one we have to check if the md5 exists (check rules)
      const differentError = storageResultsAndErrors.some(
        // @ts-ignore (types are not correct, it does not support code 'storage/unauthorized')
        (resultOrError): boolean => resultOrError.code && resultOrError.code !== 'storage/unauthorized',
      );
      if (differentError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error('Error uploading files.'), operation: 'updateAsset' });
      }

      // Data fixing
      if (assetClone.startDateTime) {
        // @ts-ignore
        assetClone.startDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.startDateTime);
      }
      if (assetClone.endDateTime) {
        // @ts-ignore
        assetClone.endDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.endDateTime);
      }

      // @ts-ignore
      assetClone.updatedDateTime = firebase.firestore.FieldValue.serverTimestamp();

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists) {
          throw getAssetError || Error('Asset not found.');
        }

        const dbAsset = getAssetSuccess?.data() as Asset;

        const updateAssetObject: { [key: string]: any } = {
          ...assetClone,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        };

        // We don't need to update shares fields if these fields remain the same
        if (dbAsset.totalValueEuro !== asset.totalValueEuro || dbAsset.sharePrice !== asset.sharePrice) {
          // This should have been queried with transaction but due to the fact that transaction.get (frontend only) does not accept queries,
          // it's not possible to do it.
          const [getInvestmentsError, getInvestmentsSuccess] = await to(
            bloqifyFirestore.collection('investments').where('asset', '==', assetRef).where('boughtSharesTotal', '>', 0).get(),
          );
          if (getInvestmentsError) {
            throw getInvestmentsError;
          }

          const [getPaymentsError, getPaymentsSuccess] = await to(
            bloqifyFirestore.collectionGroup('payments').where('asset', '==', assetRef).where('providerData.status', '==', 'open').get(),
          );
          if (getPaymentsError) {
            throw getPaymentsError;
          }

          const payments = getPaymentsSuccess!.docs.map((payment): Payment => payment.data() as Payment);

          const updateTotalValueShares = (): number => {
            if (!isNaN(assetClone.totalValueEuro) && !isNaN(assetClone.sharePrice)) {
              // It can be 0/0 = NaN
              return (assetClone.totalValueEuro / assetClone.sharePrice) || 0;
            }

            return 0;
          };

          const totalValueShares = updateTotalValueShares();
          const boughtSharesTotal = getInvestmentsSuccess!.docs.reduce(
            (docA, docB): number => (docA || 0) + docB.get('boughtSharesTotal'),
            0,
          );
          const openPaymentsTotalShares = payments.reduce((a, payment): number => a + payment.providerData.metadata.sharesAmount, 0);

          updateAssetObject.totalValueShares = totalValueShares;
          updateAssetObject.sharesAvailable = totalValueShares - (boughtSharesTotal + openPaymentsTotalShares);
        }

        transaction.update(assetRef, updateAssetObject);
      }));
      if (transactionError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updateAsset' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, payload: asset, operation: 'updateAsset' });
    },
    async handlePublishAssetById(
      { commit },
      { assetId, published }: { assetId: string, published: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handlePublishAssetById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

      const [updateAssetError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
          throw getAssetError || Error('Asset does not exist');
        }

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        transaction.update(
          assetRef,
          {
            published,
            updatedDateTime: serverTimestamp,
          },
        );
        transaction.set(
          bloqifyFirestore.collection('settings').doc('counts'),
          {
            publishedAssets: firebase.firestore.FieldValue.increment(published ? 1 : -1),
            updatedDateTime: serverTimestamp,
          },
          {
            merge: true,
          },
        );
      }));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handlePublishAssetById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handlePublishAssetById' });
    },
    async handleDeleteAssetById(
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleDeleteAssetById' });

      const [updateAssetError] = await to(bloqifyFirestore.collection('assets').doc(assetId).update(
        {
          deleted: true,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        },
      ));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handleDeleteAssetById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handleDeleteAssetById' });
    },
    async createAssetFinancialsData(
      { commit },
      { uploadedFiles, assetId, type, upperCaseType, totalAmount }: { uploadedFiles: any, assetId: string, type: string, upperCaseType: string, totalAmount: number },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: `createAsset${upperCaseType}` });
      const batch = new BatchInstance(bloqifyFirestore);
      const storageRef = bloqifyStorage.ref();
      const [getAssetError, getAssetSuccess] = await to(
        bloqifyFirestore.collection('assets').doc(assetId).get(),
      );
      if (getAssetError || !getAssetSuccess) {
        throw Error('Error retrieving the asset.');
      }

      const assetRef = getAssetSuccess!.ref;
      const assetTypeRef = assetRef.collection(type).doc();

      const files: { [key: string]: File[] } = {};
      const storageChildren: { file: File, ref: firebase.storage.Reference }[] = [];

      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      try {
        await Promise.all(uploadedFiles.map(async (uploadedFile): Promise<void> => {
          const dateNow = new Date();
          const dateProcessed = `${dateNow
            .getFullYear()}_${dateNow.getMonth() + 1}_${dateNow.getDate()}_${dateNow.getHours()}_${dateNow.getMinutes()}_${dateNow.getSeconds()}`;

          const { file } = uploadedFile;
          const fullPath = `assets/${assetRef.id}/${type}/${dateProcessed}_${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[`${dateProcessed}_${file.name}`]) {
            files[`${dateProcessed}_${file.name}`].push(file);
          } else {
            files[`${dateProcessed}_${file.name}`] = [file];
          }

          // Earning related to the Type
          batch.set(
            assetTypeRef,
            {
              asset: assetRef,
              createdDateTime: serverTimestamp,
              fileName: `${dateProcessed}_${file.name}`,
              deleted: false,
            },
          );
          Papa.parse(file, {
            error: (e): void => commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `createAsset${upperCaseType}` }),
            complete: async (results): Promise<any> => {
              try {
                await Promise.all(results.data.map(async (row, i): Promise<void> => {
                  if (row.length !== 0 && row[0].length !== 0 && row.length === 4) {
                    const [getInvestorsError, getInvestorsSuccess] = await to(
                      bloqifyFirestore.collection('investors').where('customId', '==', Number(row[3])).limit(1).get(),
                    );

                    if (getInvestorsError || getInvestorsSuccess!.empty) {
                      throw Error('Error retrieving the investor.');
                    }

                    const investorRef = getInvestorsSuccess!.docs[0].ref;

                    const [getInvestmentsError, getInvestmentsSuccess] = await to(
                      bloqifyFirestore.collection('investments')
                      .where('investor', '==', investorRef)
                      .where('asset', '==', assetRef)
                      .limit(1)
                      .get(),
                    );
                    if (getInvestmentsError || getInvestmentsSuccess!.empty) {
                      throw getInvestmentsError || Error('Error retrieving the investment.');
                    }
                    const investmentRef = getInvestmentsSuccess!.docs[0].ref;
                    // euro / shares amount
                    const amount = row[2].replace(',', '.');
                    if (type === 'earnings') {
                      batch.update(
                        investmentRef,
                        {
                          totalEarnings: firebase.firestore.FieldValue.increment(Number(amount)),
                          updatedDateTime: serverTimestamp,
                        },
                      );
                    } else if (type === 'repayments') {
                      batch.update(
                        investmentRef,
                        {
                          totalRepayments: firebase.firestore.FieldValue.increment(-Math.abs(Number(amount))),
                          updatedDateTime: serverTimestamp,
                        },
                      );
                    }

                    const investmentTypeRef = investmentRef.collection(type).doc();

                    const transactionDate = row[0];

                    const getDate = (dateString: string): Date => {
                      let symbolToUse;
                        if (dateString.includes('-')) {
                          symbolToUse = '-';
                        } else if (dateString.includes('/')) {
                          symbolToUse = '/';
                        }
                      const elements = dateString.split(symbolToUse);
                      return new Date(`${elements[2]}/${elements[1]}/${elements[0]}`);
                    };
                    const paymentDate = row[1];
                    batch.set(
                      investmentTypeRef,
                      {
                        investment: investmentRef,
                        amount: Number(amount),
                        createdDateTime: serverTimestamp,
                        clientId: Number(row[3]),
                        transactionDate: firebase.firestore.Timestamp.fromDate(getDate(transactionDate)),
                        period: firebase.firestore.Timestamp.fromDate(getDate(paymentDate)),
                        deleted: false,
                        investor: investorRef,
                        assetType: assetTypeRef,
                      },
                    );

                    // Convert string to timestamp
                    let symbolToUse;
                      if (paymentDate.includes('-')) {
                        symbolToUse = '-';
                      } else if (paymentDate.includes('/')) {
                        symbolToUse = '/';
                      }
                    const elements = paymentDate.split(symbolToUse);
                    const newDate = new Date(`${elements[2]}/${elements[1]}/${elements[0]}`);

                    const processedPaymentDate = firebase.firestore.Timestamp.fromDate(newDate);

                    batch.update(
                      assetTypeRef,
                      {
                        totalAmount: Number(amount),
                        period: processedPaymentDate,
                      },
                    );
                  }
                }));

                if (type === 'earnings') {
                  batch.update(
                    assetRef,
                    {
                      totalEarnings: firebase.firestore.FieldValue.increment(totalAmount),
                      updatedDateTime: serverTimestamp,
                    },
                  );
                } else if (type === 'repayments') {
                  batch.update(
                    assetRef,
                    {
                      totalRepayments: firebase.firestore.FieldValue.increment(totalAmount),
                      updatedDateTime: serverTimestamp,
                    },
                  );
                }
                try {
                  await batch.commit();
                } catch (e) {
                  return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `createAsset${upperCaseType}` });
                }
              } catch (e) {
                return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `createAsset${upperCaseType}` });
              }

              return { ok: true };
            },
          });
        }));
      } catch (e) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `createAsset${upperCaseType}` });
      }

      // Uploading all files including hashes
      try {
        await Promise.all(storageChildren.map(async (child): Promise<any> => {
          const md5Hash = await generateFileMd5Hask(child.file, true);

          return child.ref.put(child.file, { customMetadata: { md5Hash } });
        }));
      } catch (e) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `createAsset${upperCaseType}` });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: `createAsset${upperCaseType}` });
    },
    async deleteAssetFinancialsData(
      { commit },
      { fileDownloadUrl, fileName, assetId, type, upperCaseType, typeId, totalAmount }:
      { fileDownloadUrl: string, fileName: string, assetId: string, type: string, upperCaseType: string, typeId: string, totalAmount: number },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: `deleteAsset${upperCaseType}` });
      const batch = new BatchInstance(bloqifyFirestore);
      const storageRef = bloqifyStorage.ref();
      const [getAssetError, getAssetSuccess] = await to(
        bloqifyFirestore.collection('assets').doc(assetId).get(),
      );
      if (getAssetError) {
        throw Error('Error retrieving the asset.');
      }

      const assetRef = getAssetSuccess!.ref;
      const typeFileFullPath = `assets/${assetRef.id}/${type}/${fileName}`;
      const typeFileRef = storageRef.child(typeFileFullPath);

      const [getFileError, response] = await to(axios.get(
        fileDownloadUrl,
        {
          responseType: 'arraybuffer',
        },
      ));
      if (getFileError) {
        throw Error('There was an error retrieving the file.');
      }

      const responseBlob = response!.data as Blob;
      const file = new File([responseBlob], fileName);

      const assetTypeRef = await assetRef.collection(type).doc(typeId);
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      // mark as Deleted the Earning/Repayment related to the Type
      batch.update(
        assetTypeRef,
        {
          deleted: true,
          updatedDateTime: serverTimestamp,
        },
      );

      Papa.parse(file, {
        error: (e): void => commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `deleteAsset${upperCaseType}` }),
        complete: async (results): Promise<void> => {
          try {
            await Promise.all(results.data.map(async (row, i): Promise<void> => {
              if (row.length !== 0 && row[0].length !== 0) {
                const [getInvestorsError, getInvestorsSuccess] = await to(
                  bloqifyFirestore.collection('investors').where('customId', '==', Number(row[3])).limit(1).get(),
                );

                if (getInvestorsError || getInvestorsSuccess!.empty) {
                  throw Error('Error retrieving the investor.');
                }

                const investorRef = getInvestorsSuccess!.docs[0].ref;

                const [getInvestmentsError, getInvestmentsSuccess] = await to(
                  bloqifyFirestore.collection('investments')
                  .where('investor', '==', investorRef)
                  .where('asset', '==', assetRef)
                  .limit(1)
                  .get(),
                );
                if (getInvestmentsError || getInvestmentsSuccess!.empty) {
                  throw Error('Error retrieving the investment.');
                }

                const investmentRef = getInvestmentsSuccess!.docs[0].ref;

                const [getInvestmentTypeError, getInvestmentTypeSuccess] = await to(
                  investmentRef.collection(type)
                  .where('assetType', '==', assetTypeRef)
                  .limit(1)
                  .get(),
                );
                if (getInvestmentTypeError || getInvestmentTypeSuccess!.empty) {
                  throw Error(`Error retrieving the investment ${type}.`);
                }

                const wasDeletedAlready = getInvestmentTypeSuccess!.docs[0].get('deleted');

                const amount = row[2].replace(',', '.');
                if (!wasDeletedAlready) {
                  // This should have been queried with transaction but due to the fact that transaction (frontend only) does not accept queries,
                  // it's not possible to do it.
                  if (type === 'earnings') {
                    batch.update(
                      investmentRef,
                      {
                        totalEarnings: firebase.firestore.FieldValue.increment(-Number(amount)),
                        updatedDateTime: serverTimestamp,
                      },
                    );
                  } else if (type === 'repayments') {
                    batch.update(
                      investmentRef,
                      {
                        totalRepayments: firebase.firestore.FieldValue.increment(-Number(amount)),
                        updatedDateTime: serverTimestamp,
                      },
                    );
                  }
                  const investmentTypeRef = getInvestmentTypeSuccess!.docs[0].ref;

                  batch.update(
                    investmentTypeRef,
                    {
                      deleted: true,
                      updatedDateTime: serverTimestamp,
                    },
                  );
                }

                batch.update(
                  assetTypeRef,
                  {
                    totalAmount: firebase.firestore.FieldValue.increment(-Number(amount)),
                  },
                );

                if (type === 'earnings') {
                  batch.update(
                    assetRef,
                    {
                      totalEarnings: firebase.firestore.FieldValue.increment(-totalAmount),
                      updatedDateTime: serverTimestamp,
                    },
                  );
                } else if (type === 'repayments') {
                  batch.update(
                    assetRef,
                    {
                      totalRepayments: firebase.firestore.FieldValue.increment(-totalAmount),
                      updatedDateTime: serverTimestamp,
                    },
                  );
                }
              }
            }));

            try {
              await batch.commit();
            } catch (e) {
              return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `deleteAsset${upperCaseType}` });
            }

            try {
              // delete file from storage
              await typeFileRef.delete();
            } catch (e) {
              return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `deleteAsset${upperCaseType}` });
            }

            return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: `deleteAsset${upperCaseType}` });
          } catch (e) {
            return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: `deleteAsset${upperCaseType}` });
          }
        },
      });
    },
    async createPaymentRequestsForAsset(
      { commit },
      { assetId }:
        { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'requestAllPaymentsOperation' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

      const [errorPayments, successPayments] = await to(bloqifyFirestore.collectionGroup('payments')
          .where('deleted', '==', false)
          .where('providerData.status', '==', PaymentStatus.Open)
          .where('asset', '==', assetRef)
          .get());

      if (errorPayments || !successPayments) {
        throw Error(errorPayments?.message || 'There was an error fetching the payments');
      }
      const timeNow = firebase.firestore.Timestamp.now();
      // Going throigh the payments twice because the request payment function doesn't work correctly when inside bloqifyFirestore.runTransaction
      // And I want to throw an error and return if the function fails before updating firebase data
      // eslint-disable-next-line consistent-return
      successPayments.docs.forEach(async (element): Promise<any> => {
        const providerData = element.get('providerData');
         // Can request again if already requested
        if (providerData.status !== PaymentStatus.Open && providerData.status !== PaymentStatus.Requested) {
          throw Error('This payment is neither open nor requested.');
        }
        const investmentRef = element.ref.parent.parent;
        if (!investmentRef) {
          throw new Error('Investment not found');
        }

        const investmentId = investmentRef.id;
        const paymentId = element.ref.id;
        const [error] = await to(
          bloqifyFunctions.httpsCallable('requestPayment')({ investmentId, paymentId }),
        );
        if (error) {
          return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: error, operation: 'requestPayment' });
        }
      });

      const [markPaymentsAsRequestedError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        // Update payment status
        successPayments.docs.forEach(async (element): Promise<any> => {
          const providerData = element.get('providerData');
          const investmentRef = element.ref.parent.parent;

          if (!investmentRef) {
            throw new Error('Investment not found');
          }

          // First send email and then update payment
          transaction.update(
            element.ref,
            {
              providerData: {
                ...providerData,
                status: PaymentStatus.Requested,
              },
              updatedDateTime: timeNow,
            },
          );
          transaction.update(
            investmentRef,
            {
              updatedDateTime: timeNow,
            },
          );
          commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'requestAllPaymentsOperation' });
        });
      }));

      if (markPaymentsAsRequestedError) {
        commit(SET_ASSET, { status: DataContainerStatus.Error, payload: markPaymentsAsRequestedError, operation: 'requestAllPaymentsOperation' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'requestAllPaymentsOperation' });
    },
    async deleteInvestmentFinancialsData(
      { commit },
      { financialsDataId, type, upperCaseType, investmentId }: { financialsDataId: string, type: string, upperCaseType: string, investmentId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: `deleteFinancialsData${upperCaseType}` });

      const [deleteInvestmentFinancialsDataError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getFinancialsDataError, getFinancialsDataSuccess] = await to(
          bloqifyFirestore.collection(`investments/${investmentId}/${type}`).doc(financialsDataId).get(),
        );
        if (getFinancialsDataError) {
          throw Error('Error retrieving the Financials Data.');
        }
        const deletedAmount = getFinancialsDataSuccess?.get('amount');

        const isDeletedAlready = getFinancialsDataSuccess?.get('deleted');
        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        if (!isDeletedAlready) {
          // mark as Deleted the Earning/Repayment related to the Type
          transaction.update(
            getFinancialsDataSuccess!.ref,
            {
              deleted: true,
              updatedDateTime: serverTimestamp,
            },
          );
          const [getInvestmentError, getInvestmentSuccess] = await to(
            bloqifyFirestore.collection('investments').doc(investmentId).get(),
          );
          if (getInvestmentError) {
            throw Error('Error retrieving the Financials Data.');
          }

          const assetId = getInvestmentSuccess!.get('asset').id;

          const [getAssetError, getAssetSuccess] = await to(
            bloqifyFirestore.collection('assets').doc(assetId).get(),
          );
          if (getAssetError) {
            throw Error('Error retrieving the asset.');
          }

          const assetRef = getAssetSuccess!.ref;

          if (type === 'repayments') {
            const pastTotalRepayments = getInvestmentSuccess?.get('totalRepayments');

            transaction.update(
            getInvestmentSuccess!.ref,
              {
                totalRepayments: new BigNumber(pastTotalRepayments).minus(deletedAmount).toNumber(),
                updatedDateTime: serverTimestamp,
              },
            );
            assetRef.update({
              totalRepayments: firebase.firestore.FieldValue.increment(-deletedAmount),
              updatedDateTime: serverTimestamp,
            });
          }

          if (type === 'earnings') {
            const pastTotalEarnings = getInvestmentSuccess?.get('totalEarnings');

            transaction.update(
            getInvestmentSuccess!.ref,
              {
                totalEarnings: new BigNumber(pastTotalEarnings).minus(deletedAmount).toNumber(),
                updatedDateTime: serverTimestamp,
              },
            );
            assetRef.update({
              totalEarnings: firebase.firestore.FieldValue.increment(-deletedAmount),
              updatedDateTime: serverTimestamp,
            });
          }
        }
        return { ok: true };
      }));
      if (deleteInvestmentFinancialsDataError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: deleteInvestmentFinancialsDataError, operation: `deleteFinancialsData${upperCaseType}` });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: `deleteFinancialsData${upperCaseType}` });
    },
    async updateInvestmentFinancialsData(
      { commit },
      { financialsDataId, type, upperCaseType, newData, investmentId }: { financialsDataId: string, type: string, upperCaseType: string, newData: any, investmentId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: `updateFinancialsData${upperCaseType}` });

      const [updateInvestmentFinancialsDataError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getFinancialsDataError, getFinancialsDataSuccess] = await to(
          bloqifyFirestore.collection(`investments/${investmentId}/${type}`).doc(financialsDataId).get(),
        );
        if (getFinancialsDataError) {
          throw Error('Error retrieving the Financials Data.');
        }

        const previousAmount = getFinancialsDataSuccess?.get('amount');
        const newAmount = newData.amount;
        const amountDifference = newAmount - previousAmount;

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        // mark as Deleted the Earning/Repayment related to the Type
        transaction.update(
          getFinancialsDataSuccess!.ref,
          {
            ...newData,
            updatedDateTime: serverTimestamp,
          },
        );

        const [getInvestmentError, getInvestmentSuccess] = await to(
          bloqifyFirestore.collection('investments').doc(investmentId).get(),
        );
        if (getInvestmentError) {
          throw Error('Error retrieving the Financials Data.');
        }

        const assetId = getInvestmentSuccess!.get('asset').id;

        const [getAssetError, getAssetSuccess] = await to(
          bloqifyFirestore.collection('assets').doc(assetId).get(),
        );
        if (getAssetError) {
          throw Error('Error retrieving the asset.');
        }

        const assetRef = getAssetSuccess!.ref;

        if (type === 'repayments') {
          const pastTotalRepayments = getInvestmentSuccess?.get('totalRepayments');

          transaction.update(
          getInvestmentSuccess!.ref,
            {
              totalRepayments: new BigNumber(pastTotalRepayments).plus(amountDifference).toNumber(),
              updatedDateTime: serverTimestamp,
            },
          );
          assetRef.update({
            totalRepayments: firebase.firestore.FieldValue.increment(amountDifference),
            updatedDateTime: serverTimestamp,
          });
        }

        if (type === 'earnings') {
          const pastTotalEarnings = getInvestmentSuccess?.get('totalEarnings');

          transaction.update(
          getInvestmentSuccess!.ref,
            {
              totalEarnings: new BigNumber(pastTotalEarnings).plus(amountDifference).toNumber(),
              updatedDateTime: serverTimestamp,
            },
          );
          assetRef.update({
            totalEarnings: firebase.firestore.FieldValue.increment(amountDifference),
            updatedDateTime: serverTimestamp,
          });
        }

        return { ok: true };
      }));
      if (updateInvestmentFinancialsDataError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateInvestmentFinancialsDataError, operation: `updateFinancialsData${upperCaseType}` });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: `updateFinancialsData${upperCaseType}` });
    },
  },
  getters: {
    getAssetTotalEuroInvested: (state, getters): Function => (asset: Asset): number => {
      const assetActiveValuation = getters.getActiveValuationByAsset(asset.id);

      const sharePrice = assetActiveValuation
        ? new BigNumber(assetActiveValuation.amount).dividedBy(asset.totalValueShares).toNumber()
        : asset.sharePrice;

      return new BigNumber(asset.totalValueShares).minus(asset.sharesAvailable)
        .times(sharePrice)
        .decimalPlaces(2)
        .toNumber();
    },
  },
};
