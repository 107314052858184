import { render, staticRenderFns } from "./LanguageSidebar.html?vue&type=template&id=784321a7&scoped=true&"
import script from "./LanguageSidebar.ts?vue&type=script&lang=ts&"
export * from "./LanguageSidebar.ts?vue&type=script&lang=ts&"
import style0 from "./LanguageSidebar.scss?vue&type=style&index=0&id=784321a7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784321a7",
  null
  
)

export default component.exports