import Vue from 'vue';
// @ts-ignore
import vueHeadful from 'vue-headful';

// @ts-ignore
import VueMoment from 'vue-moment';
import IdleVue from 'idle-vue';
import moment from 'moment';
// Library to create slugs
// @see https://github.com/dodo/node-slug
import slug from 'slug';
import { ClientTable } from 'vue-tables-2';
import VueUploadComponent from 'vue-upload-component';
import GmapVue from 'gmap-vue';
import LazyLoad from 'vue-lazyload';
import { Promised } from 'vue-promised';
import vSelect from 'vue-select';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-select/src/scss/vue-select.scss';
import store from '@/store';
import router from './router';

const env = process.env;
const NODE_ENV = env.NODE_ENV;
const isDevelopmentEnvironment = NODE_ENV === 'development';
const isProductionEnvironment = NODE_ENV === 'production';

// Make globally available as $slug(...) in
// templates and as this.$slug(...) in TypeScript
Object.defineProperty(Vue.prototype, '$slug', { value: slug });

Vue.use(ClientTable);

// Vue file upload component
Vue.component('FileUpload', VueUploadComponent);

// Library to set page titles
// @see https://github.com/troxler/vue-headful
Vue.component('VueHeadful', vueHeadful);

// Library for handling dates and times
// @see https://github.com/brockpetrie/vue-moment
// @see http://momentjs.com/docs/#/use-it/
Vue.use(VueMoment, { moment });
moment.locale('en');

// Changing moment locale
window.addEventListener('storage', (event): void => {
  if (event.storageArea === localStorage && localStorage.getItem('vue-i18n-language')
    && localStorage.getItem('vue-i18n-language') !== moment.locale()) {
    moment.locale(localStorage.getItem('vue-i18n-language')!);
  }
}, false);

Vue.use(LazyLoad);

// Library to handle loading
// @see https://github.com/posva/vue-promised
Vue.component('Promised', Promised);

// @ts-ignore
Vue.component('VSelect', vSelect);

Vue.use(IdleVue, {
  store,
  // 3 hours at dev, 25 hours at prod
  idleTime: isDevelopmentEnvironment ? 10800000 : 90000000,
});

// Google maps config
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_BLOQADMIN_FIREBASE_API_KEY,
    libraries: 'places',
  },
});

if (isProductionEnvironment) {
  Sentry.init({
    Vue,
    dsn: env.VUE_APP_BLOQADMIN_SENTRY,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    environment: env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID,
    tracesSampleRate: 1.0,
  });
}
