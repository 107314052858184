
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  import changeCase from 'change-case';
  // @ts-ignore
  import { Toast } from 'vuex-toast';
  import { State } from '@/models/State';
  import Sidebar from '@/components/navigation/Sidebar.vue';
  import { ManagerRole } from '@/models/manager/Manager';
  import initialiseValidators from '@/boot/validators';

  initialiseValidators();

  @Component({
    components: {
      Toast,
      Sidebar,
    },
  })
  export default class App extends Vue {
    defaultLayout: string = 'single';

    @Action refreshAuthenticatedUserToken!: Function;
    @Action logOut!: Function;

    @StateClass manager!: State['manager'];
    @StateClass currentManager!: State['currentManager'];
    @StateClass settings!: State['settings'];

    @Getter getCurrentManagerRole!: ManagerRole;

    @Watch('manager', { deep: true })
    onFireStoreUserChange(newState: State['manager'], oldState: State['manager']): void {
      if (newState !== null && oldState !== null) {
        // Role changed, so make sure client side we're up to date
        if (newState.metadata.roleSetTime !== oldState.metadata.roleSetTime) {
          this.refreshAuthenticatedUserToken();
        }
      }
    }

    /**
     * Log out user when idle.
     */
    @Watch('isAppIdle')
    onNewAppIdle(newAppIdle: boolean, oldAppIddle: boolean): void {
      if (newAppIdle && !oldAppIddle && this.currentManager) {
        this.logOut({ redirect: '/login', idle: newAppIdle });
      }
    }

    /**
     * Computed property to return the layout defined
     * in router.ts as meta property. Returns CamelCased
     * layout (i.e. Vue component defined in layouts.ts).
     */
    get layout(): string {
      const layout: string = this.$route.meta?.layout || this.defaultLayout;

      return changeCase.pascalCase(`${layout}-layout`);
    }

    get isUserSuperAdmin(): boolean {
      if (this.getCurrentManagerRole) {
        return (this.getCurrentManagerRole === ManagerRole.Superadmin);
      }
      return false;
    }

    get maintenanceMode(): boolean {
      if (!this.settings) {
        return false;
      }
      return this.settings.bloqadminMaintenance;
    }
  }

