import firebase from 'firebase/app';

export interface Asset {
  id?: string;
  fundType: string;
  brochure?: string[];
  city: string;
  country: string;
  createdDateTime: firebase.firestore.Timestamp;
  deleted: boolean;
  /**
   * Firestore limitation of nested arrays forces to create intermediate object
   * when `dividendsFormat.contents[0][0] === 'fixed'`, it's fixed net dividends, so contents `length === 1`.
   */
  dividendsFormat: { contents: [string, number] }[];
  earningsPaymentSchedule: string;
  emissionCost: number;
  euroMin: number;
  fixedDividends?: boolean;
  floorPlanImages?: string[];
  houseNumber: string;
  images?: string[];
  investmentCase: string;
  name: string;
  postalCode: string;
  premium: boolean;
  propertyDetails: string;
  risks: string;
  prospectus?: string[];
  published: boolean;
  returnsAfterEnd?: number;
  sharePrice: number;
  sharesAvailable: number;
  startDateTime: firebase.firestore.Timestamp;
  endDateTime: firebase.firestore.Timestamp;
  street: string;
  totalValueEuro: number;
  totalValueShares: number;
  updatedDateTime?: firebase.firestore.Timestamp;
  reservation?: boolean;
}

// Asset Converted created in order to just load from firestore the required fields
export class AssetConverter {
  constructor(
    readonly createdDateTime: firebase.firestore.Timestamp,
    readonly name: string,
    readonly endDateTime: firebase.firestore.Timestamp,
    readonly startDateTime: firebase.firestore.Timestamp,
    readonly fundType: string,
    readonly dividendsFormat: Array<any>,
    readonly fixedDividends: boolean,
    readonly returnsAfterEnd: number,
  ) {
    this.createdDateTime = createdDateTime;
    this.name = name;
    this.endDateTime = endDateTime;
    this.startDateTime = startDateTime;
    this.fundType = fundType;
    this.dividendsFormat = dividendsFormat;
    this.fixedDividends = fixedDividends;
    this.returnsAfterEnd = returnsAfterEnd;
  }
}

export const assetConverter = {
  toFirestore(asset): firebase.firestore.DocumentData {
    return {
      createdDateTime: asset.createdDateTime,
      name: asset.name,
      endDateTime: asset.endDateTime,
      startDateTime: asset.tartDateTime,
      fundType: asset.fundType,
      dividendsFormat: asset.dividendsFormat,
      fixedDividends: asset.fixedDividends,
      returnsAfterEnd: asset.returnsAfterEnd,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): AssetConverter {
    const data = snapshot.data(options)!;
    return new AssetConverter(
      data.createdDateTime,
      data.name,
      data.endDateTime,
      data.tartDateTime,
      data.fundType,
      data.dividendsFormat,
      data.fixedDividends,
      data.returnsAfterEnd,
    );
  },
};
/**
 * Defining all the export type.
 */
export const enum ExportType {
  Excel = 'excel',
  Csv = 'csv',
}
